import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'

import SEO from '../components/seo'
import Layout from '../components/layout'
import SedeGls from '../components/SedeGls'

const sedi = [
  {
    id: 1,
    nomeSede: 'Catania',
    indirizzo: 'Blocco Giancata',
    cap: '95121',
    citta: 'Catania',
    provincia: 'CT',
    tel: '095/7482811',
    fax: '095/7482881',
    email: 'catania@gls-italy.com',
    position: [37.428067, 15.037386],
  },
  {
    id: 2,
    nomeSede: 'Messina',
    indirizzo: 'SS 114 - km 6,193 km 14',
    cap: '98131',
    citta: 'Milli Marina',
    provincia: 'ME',
    tel: '090/6255003',
    fax: '090/6255342',
    email: 'messinanord@gls-italy.com',
    position: [38.130044, 15.520909],
  },
  {
    id: 3,
    nomeSede: 'Milazzo',
    indirizzo: 'Via Madonna delle Grazie, 88',
    cap: '98057',
    citta: 'Milazzo',
    provincia: 'ME',
    tel: '090/2139600',
    fax: '090/2139601',
    email: 'milazzo@gls-italy.com',
    position: [38.191444, 15.253117],
  },
  {
    id: 4,
    nomeSede: 'Ragusa',
    indirizzo: 'Contrada Maiorana s.n.',
    cap: '97100',
    citta: 'Ragusa',
    provincia: 'RG',
    tel: '0932/251005',
    fax: '0932/258774',
    email: 'ragusa@gls-italy.com',
    position: [36.931544, 14.65749],
  },
  {
    id: 5,
    nomeSede: 'Siracusa',
    indirizzo: 'Contrada Targia s.n.',
    cap: '96100',
    citta: 'Siracusa',
    provincia: 'SR',
    tel: '0931/490031',
    fax: '0931/491850',
    email: 'siracusa@gls-italy.com',
    position: [37.097168, 15.207938],
  },
  {
    id: 6,
    nomeSede: 'Belpasso',
    indirizzo: 'Contrada Fureria s.n.',
    cap: '95032',
    citta: 'Belpasso',
    provincia: 'CT',
    tel: '095/16959353',
    fax: '095/16959340',
    email: 'belpasso@gls-italy.com',
    position: [37.546757, 14.972601],
  },
]

const Contatti = () => {
  return (
    <Layout>
      <SEO
        title="Contatti"
        description="Contatti Mediterranea Express - Sedi di Catania, Messina, Milazzo, Ragusa e Siracusa"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          Contatti
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {typeof window !== 'undefined' && (
          <Map
            center={[37.507528, 14.59309]}
            zoom={8}
            scrollWheelZoom={false}
            className="block w-full lg:rounded-t-lg"
            style={{ height: 450 }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {sedi.map((sede) => {
              return (
                <Marker key={sede.id} position={sede.position}>
                  <Popup>
                    <SedeGls sede={sede} />
                  </Popup>
                </Marker>
              )
            })}
          </Map>
        )}
        <div className="py-12 px-12 flex flex-wrap lg:justify-center">
          {sedi.map((sede) => {
            return (
              <div key={sede.id} className="mb-12 w-full sm:w-1/2 lg:w-1/3">
                <SedeGls sede={sede} />
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Contatti
